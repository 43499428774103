<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  div(v-if='isLoading' class="section-pricing")
    v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
      v-row(class="fill-height" align-content="center" justify="center")
        //- mobile view
        v-col(v-if="isMobile()" class="text-start" cols="12" )
          v-chip(large label color="grey lighten-3" class="")
            p(class="grey--text text--darken-3 text-body-1 text-none mt-2 font-weight-regular") Powered by 
              span(class="grey--text text--darken-3 text-none font-weight-bold") CheqqMate
              v-progress-linear(class="mt-1" color="grey darken-3" indeterminate rounded height="6")
        //- desktop view
        v-col(v-else class="text-center" cols="4")
          v-chip(large label color="grey lighten-3" class="")
            p(class="grey--text text--darken-3 text-body-1 text-none mt-2 font-weight-regular") Powered by 
              span(class="grey--text text--darken-3 text-none font-weight-bold") CheqqMate
              v-progress-linear(class="mt-1" color="grey darken-3" indeterminate rounded height="6")
  //- version 1
  div(v-else-if='form.imageUrl')
    v-row(:class = "[isMobile() ? 'd-flex flex-column align-center justify-center': '' ]" )
      v-col(cols="6")
          div(v-if='form.id !== 9' style="justify-content: center; align-items: center; display: flex;" )
            h4(:class = "['text-none mt-2 font-weight-bold', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex }") {{ form.formName }}
              h6(:class ="['text-none font-weight-regular', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex}") {{ form.formDescription }}
              v-btn(elevation="0" class="mt-4" large @click="nextSlide" value="center" :color="secondaryColorHex")
                span(:style="{color: buttonTextColorHex }" class="text-h5 text-none font-weight-medium") Start Form
          div(v-else)
            v-sheet(
              elevation="0"
              class="pa-4"
              :color="primaryColor"
              :dark="contrast"
              :style="{color: textColorHex}"
            )
              h3(class="text-none" :style="{color: textColorHex}") {{ form.formName }}
                h6(class="text-none font-weight-regular" :style="{color: textColorHex}") {{ form.formDescription }}  
      v-col(cols="6")
        div(v-if='form.imageUrl')
            v-img(
              max-height="300px"
              :src="form.imageUrl" 
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              class="grey darken-1"
              style="border-radius: 8px; opacity: 0.9;"
            )
        div(v-else)
          v-sheet(
            elevation="0"
            class="pa-2"
            color="grey lighten-5"
            rounded="lg"
            :style="{opacity: 0.4}"
          )
            v-avatar(size="200"  class="mx-auto" style="justify-content: center; align-items: center; display: flex;")
              v-icon(:size="125" :color="primaryColor" :style="{opacity: 0.2}") mdi-image-area
  div(v-else-if='!form.imageUrl')
    v-row(class="d-flex flex-column align-center justify-center")
      v-col(cols="12")
        div(v-if='form.id !== 9' style="justify-content: center; align-items: center; display: flex;" )
          h4(class="text-none mt-2 font-weight-bold text-center" :style="{color: textColorHex }") {{ form.formName }}
            h6(class="text-none font-weight-regular text-center" :style="{color: textColorHex}") {{ form.formDescription }}
            v-btn(elevation="0" class="mt-4" large @click="nextSlide" value="center" :color="secondaryColorHex")
              span(:style="{color: buttonTextColorHex }" class="text-h5 text-none font-weight-medium") Start Form
        div(v-else)
          v-sheet(
            elevation="0"
            class="pa-4"
            :color="primaryColor"
            :dark="contrast"
            :style="{color: textColorHex}"
          )
            h3(class="text-none" :style="{color: textColorHex}") {{ form.formName }}
              h6(class="text-none font-weight-regular" :style="{color: textColorHex}") {{ form.formDescription }}
              v-btn(elevation="0" class="mt-4" large @click="nextSlide" value="center" :color="secondaryColorHex")
                span(:style="{color: buttonTextColorHex }" class="text-h5 text-none font-weight-medium") Start Form
</template>

<script>
import bg1Svg from '../templates/sprinkle.vue';
import bg2Svg from '../templates/simple-shiny.vue';
import bg3Svg from '../templates/meteor.vue';
import bg4Svg from '../templates/animated-shape.vue';
import bg5Svg from '../templates/wave.vue';

import { mapGetters } from "vuex";
export default {
  name: "Cover",
  // props: {
  //   id: {
  //     type: String,
  //     required: true
  //   },
  // },
  components: {
    bg1Svg,
    bg2Svg,
    bg3Svg,
    bg4Svg,
    bg5Svg
  },
  data() {
    return {
      id2: '', 
      primaryColor: '#154360', // Default color
      bg: 'bg1',
      text: 'center',
      text1: 'center',
      slide: 'cover',
      selectedSvg: bg1Svg, // Default SVG bg
    };
  },
  computed: {
    ...mapGetters(['getSlide','isLoading']),
    embedded() {
      return this.$store.state.embedded;
    },
    getColorVariants() {
      return this.getLighterAndDarkerShades(this.primaryColor) || [];
    },
    form() {
      return this.$store.state.form;
    },
    textColorHex() {
      return this.$store.state.textColorHex;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
  },

  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
        return true;
      }

      return false;
    },
    changeColor(newColor) {
      this.primaryColor = newColor;
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    submit() {
      alert("submit");
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },

    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },

    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },

    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 30),
        this.adjustColor(g, 30),
        this.adjustColor(b, 30)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      return [lighterShade, darkerShade];
    }
  },
};
</script>

<style scoped>
body {
  width: 100%;
  margin: 0;
}
html {
  height: auto;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}
.title {
    display: flex;
    text-align: right;
    border-radius: 8px;
}
.upload-area {
    height: 400px;
    width: 100%;
    /* display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 8px; */
  }
  .container {
    margin: 2rem;
  }
  .section-pricing {
    background-image: url("../landing/CheqqMateBackgroud.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(246, 249, 252);
  }
</style>